import React from "react";

import valid from "../../assets/img/result/verified_checkmark@2x.png";
import invalid from "../../assets/img/result/verified_question@2x.png";

type Props = {
  type: string;
};

function VerifiedIcon(props: Props) {
  let src = "";
  props.type === "valid" ? (src = valid) : (src = invalid);

  return (
    <img
      src={src}
      id={"icon_" + props.type}
      alt={"icon " + props.type}
      className="h-24 mx-auto"
    />
  );
}

export default VerifiedIcon;
