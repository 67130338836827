import React from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { RootState } from "../redux";
import {
  setInvocationIsValid,
  setDeviceIsSupported,
  setBrowserIsSupported,
  setScreenIsBigEnough,
  saveSDK,
} from "../redux/modules/app";
import { setScaleFactor } from "../redux/modules/swipe";
import { setErrorCode } from "../redux/modules/app";
import { connect } from "react-redux";
import { UsabilityResponse } from "@prismadelabs/prismaid";
import { getScaleFactor } from "../helpers/scale";
import { setNeedsTouchSensitivity } from "../redux/modules/app";
import { WithTranslation } from "react-i18next";
import { compose } from "redux";

const mapStateToProps = (state: RootState) => ({
  sdk: state.app.sdk,
});

const mapDispatchToProps = {
  setInvocationIsValid,
  setDeviceIsSupported,
  setBrowserIsSupported,
  setScreenIsBigEnough,
  setNeedsTouchSensitivity,
  setScaleFactor,
  setErrorCode,
  saveSDK,
};

type OpenProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation;

type OpenStates = {
  redirect: string | null;
  scaleIsReady: boolean;
  supportedIsReady: boolean;
};

// component
class Open extends React.Component<OpenProps, OpenStates> {
  constructor(props: OpenProps) {
    super(props);

    this.state = {
      redirect: null,
      scaleIsReady: false,
      supportedIsReady: false,
    };

    props.setInvocationIsValid(true);

    this.getSDKInitializationData();
  }

  getSDKInitializationData() {
    const sdk = this.props.sdk;
    sdk.setTwoFingerHoldingMode(true);

    // TODO: enable german/english
    // i18n.changeLanguage(sdk.getFinalSDKLanguage());

    sdk.getInitialisationSubject().subscribe((response) => {
      if (response.ppi) {
        var scale = getScaleFactor(response.ppi, response.devicePixelRatio);

        if (!Number.isNaN(scale)) {
          this.props.setScaleFactor(scale);
        }
      } else {
        this.props.setDeviceIsSupported(false);
      }

      if (response.deviceSupport?.requirements?.includes("touchsensitivity")) {
        this.props.setNeedsTouchSensitivity(true);
      }

      setTimeout(() => {
        this.setState({
          scaleIsReady: true,
        });
      }, 10);
    });

    sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
      if (response.event === "device_not_supported") {
        this.props.setDeviceIsSupported(false);
      }

      if (response.event === "browser_not_supported") {
        this.props.setBrowserIsSupported(false);
      }

      if (response.event === "display_too_small_displacement") {
        this.props.setScreenIsBigEnough(false);
      }

      if (response.event === "display_small_should_add_to_home") {
        this.props.setScreenIsBigEnough(false);
      }

      this.setState({
        supportedIsReady: true,
      });
    });

    this.props.saveSDK(sdk);
  }

  componentWillUnmount() {
    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    // note: UsabilitySubject may also come back multiple times or not at all.
    // Redirect to next screen when scaleIsReady, and add some wait-time to allow for possible usabilitySubjectResponse.
    if (this.state.scaleIsReady) {
      return <Redirect to="/app" />;
    }

    return (
      <div>
        <Helmet>
          <title>open</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <body></body>
        </Helmet>
        {/* TODO: show spinner while waiting */}
      </div>
    );
  }
}

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(Open);
