import { typedAction } from "../helpers";
import { SwipeType } from "../../enums";
import { ConnectivityStatus } from "@prismadelabs/prismaid";

export type SwipeResult = {
  title: string;
  message: string;
  shouldRender?: boolean;
};

type SwipeState = {
  scaleFactor: number;
  networkStatus: ConnectivityStatus;
  progress: number;
  showDot: boolean;
  showRedAlert: boolean;
  showGreenAlert: boolean;
  isSuccess: boolean;
  showButtons: boolean;
  swipeResults: SwipeResult[];
  count: number;
  swipeCounter: number;
  swipeType: SwipeType | null;
};

const initialState: SwipeState = {
  scaleFactor: 0.5,
  networkStatus: ConnectivityStatus.ok,
  progress: 0,
  showDot: false,
  showRedAlert: false,
  showGreenAlert: false,
  isSuccess: false,
  showButtons: false,
  count: 0,
  swipeCounter: 0,
  swipeType: null,
  swipeResults: [
    {
      title: "",
      message: "",
      shouldRender: true,
    },
  ],
};

export const setSwipeType = (swipeType: SwipeType) => {
  return typedAction("swipe/SET_SWIPETYPE", swipeType);
};

export const setScaleFactor = (scaleFactor: number) => {
  return typedAction("swipe/SET_SCALEFACTOR", scaleFactor);
};

export const setNetworkStatus = (networkStatus: ConnectivityStatus) => {
  return typedAction("swipe/SET_NETWORK_STATUS", networkStatus);
};

export const setProgress = (progress: number) => {
  return typedAction("swipe/SET_PROGRESS", progress);
};

export const setShowDot = (showDot: boolean) => {
  return typedAction("swipe/SET_SHOW_DOT", showDot);
};

export const setShowRedAlert = (showRedAlert: boolean) => {
  return typedAction("swipe/SET_SHOW_RED_ALERT", showRedAlert);
};

export const setShowGreenAlert = (showGreenAlert: boolean) => {
  return typedAction("swipe/SET_SHOW_GREEN_ALERT", showGreenAlert);
};

export const setIsSuccess = (isSuccess: boolean) => {
  return typedAction("swipe/SET_IS_SUCCESS", isSuccess);
};

export const setShowButtons = (showButtons: boolean) => {
  return typedAction("swipe/SET_SHOW_BUTTONS", showButtons);
};

export const setCount = (count: number) => {
  return typedAction("swipe/SET_COUNT", count);
};

export const setSwipeCounter = (swipeCounter: number) => {
  return typedAction("swipe/SET_SWIPECOUNTER", swipeCounter);
};

export const addSwipeResult = (result: SwipeResult) => {
  return typedAction("swipe/ADD_SWIPE_RESULT", result);
};

export const setSwipeResults = (results: SwipeResult[]) => {
  return typedAction("swipe/SET_SWIPE_RESULTS", results);
};

export const clearSwipeResults = () => {
  return typedAction("swipe/CLEAR_SWIPE_RESULTS");
};

export const getTransactionData = () => {
  return;
};

type SwipeAction = ReturnType<
  | typeof setScaleFactor
  | typeof setNetworkStatus
  | typeof setProgress
  | typeof setShowDot
  | typeof setShowRedAlert
  | typeof setShowGreenAlert
  | typeof setIsSuccess
  | typeof setShowButtons
  | typeof setCount
  | typeof setSwipeCounter
  | typeof addSwipeResult
  | typeof setSwipeResults
  | typeof clearSwipeResults
  | typeof setSwipeType
>;

export function swipeReducer(
  state = initialState,
  action: SwipeAction
): SwipeState {
  switch (action.type) {
    case "swipe/SET_SCALEFACTOR":
      return {
        ...state,
        scaleFactor: action.payload,
      };
    case "swipe/SET_NETWORK_STATUS":
      return {
        ...state,
        networkStatus: action.payload,
      };
    case "swipe/SET_PROGRESS":
      return {
        ...state,
        progress: action.payload,
      };
    case "swipe/SET_SHOW_DOT":
      return {
        ...state,
        showDot: action.payload,
      };
    case "swipe/SET_SHOW_RED_ALERT":
      return {
        ...state,
        showRedAlert: action.payload,
      };
    case "swipe/SET_SHOW_GREEN_ALERT":
      return {
        ...state,
        showGreenAlert: action.payload,
      };
    case "swipe/SET_IS_SUCCESS":
      return {
        ...state,
        isSuccess: action.payload,
      };
    case "swipe/SET_SHOW_BUTTONS":
      return {
        ...state,
        showButtons: action.payload,
      };
    case "swipe/SET_COUNT":
      return {
        ...state,
        count: action.payload,
      };
    case "swipe/SET_SWIPECOUNTER":
      return {
        ...state,
        swipeCounter: action.payload,
      };
    case "swipe/ADD_SWIPE_RESULT":
      return {
        ...state,
        swipeResults: [...state.swipeResults, action.payload],
      };
    case "swipe/SET_SWIPE_RESULTS":
      return {
        ...state,
        swipeResults: action.payload,
      };
    case "swipe/CLEAR_SWIPE_RESULTS":
      return {
        ...state,
        count: 0,
        swipeResults: [
          {
            title: "",
            message: "",
            shouldRender: true,
          },
        ],
      };
    case "swipe/SET_SWIPETYPE":
      return {
        ...state,
        swipeType: action.payload,
      };
    default:
      return state;
  }
}
