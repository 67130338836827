import React from "react";
import { Helmet } from "react-helmet";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { ErrorCode } from "../enums";
import Card from "../components/common/ui/Card";
import CenteredLayout from "../components/common/ui/CenteredLayout";
import Button from "../components/common/ui/Button";
import { compose } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";

const mapStateToProps = (state: RootState) => ({
  errorCode: state.app.errorCode,
  swipeType: state.swipe.swipeType,
});

const mapDispatchToProps = {};

type InvalidProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation &
  RouteComponentProps;

type InvalidStates = {
  redirect: any;
};

type ErrorMessage = {
  title: string;
  body: string;
  button?: ErrorButton;
};

type ErrorButton = {
  title: string;
  action: any;
};

// component
class Invalid extends React.Component<InvalidProps, InvalidStates> {
  private errorMessage: ErrorMessage;

  constructor(props: InvalidProps) {
    super(props);

    this.state = {
      redirect: null,
    };

    switch (this.props.errorCode) {
      case ErrorCode.UnsupportedDevice: {
        this.errorMessage = {
          title: props.t("invalid:invalidDevice.title"),
          body: props.t("invalid:invalidDevice.body"),
        };
        break;
      }

      case ErrorCode.UnsupportedBrowser: {
        this.errorMessage = {
          title: props.t("invalid:invalidBrowser.title"),
          body: props.t("invalid:invalidBrowser.body"),
        };
        break;
      }

      case ErrorCode.ScreenTooSmall: {
        this.errorMessage = {
          title: props.t("invalid:screenTooSmall.title"),
          body: props.t("invalid:screenTooSmall.body"),
          button: {
            title: props.t("invalid:proceedAnyway"),
            action: this.redirectToSwipe,
          },
        };
        break;
      }

      case ErrorCode.InvalidInvocation: {
        this.errorMessage = {
          title: props.t("invalidLink.title"),
          body: props.t("invalidLink.body"),
        };
        this.redirectToStart();
        break;
      }

      default: {
        this.errorMessage = {
          title: props.t("error.title"),
          body: props.t("error.body"),
        };
        break;
      }
    }
  }

  componentDidUpdate() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  redirectToSwipe = () => {
    this.props.history.push("/swipe");
  };

  redirectToStart = () => {
    this.props.history.push("/");
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const btn = this.errorMessage.button ? (
      <Button onClick={this.errorMessage.button.action} className="w-full mt-6">
        <p className="w-full text-lg font-medium">
          {this.errorMessage.button.title}
        </p>
      </Button>
    ) : (
      ""
    );

    return (
      <div>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <div className="w-screen h-screen">
          <CenteredLayout className="bg-gray-900 bg-opacity-75">
            <Card>
              <h1 className="text-lg font-extrabold">
                {this.errorMessage.title}
              </h1>
              <p className="mt-2 whitespace-pre-wrap">
                {this.errorMessage.body}
              </p>
              {btn}
            </Card>
          </CenteredLayout>
        </div>
      </div>
    );
  }
}

export default withRouter(
  compose<any>(
    withTranslation("invalid"),
    connect(mapStateToProps, mapDispatchToProps)
  )(Invalid)
);
