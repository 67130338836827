import { typedAction } from "../helpers";
import { ErrorCode } from "../../enums";
import { PrismaSDK } from "@prismadelabs/prismaid";

type AppState = {
  errorCode: ErrorCode | null;
  sdk: PrismaSDK;
  invocationIsValid: boolean;
  deviceIsSupported: boolean;
  browserIsSupported: boolean;
  screenIsBigEnough: boolean;
  needsTouchSensitivity: boolean;
  touchSensitivityIsSet: boolean;
};

const initialState: AppState = {
  errorCode: null,
  sdk: new PrismaSDK(process.env.REACT_APP_SDK_API_KEY_STRIP!),
  invocationIsValid: false,
  deviceIsSupported: true,
  browserIsSupported: true,
  screenIsBigEnough: true,
  needsTouchSensitivity: false,
  touchSensitivityIsSet: false,
};

export const setErrorCode = (errorCode: ErrorCode) => {
  return typedAction("app/SET_ERRORCODE", errorCode);
};

export const saveSDK = (sdk: PrismaSDK) => {
  return typedAction("app/SAVE_SDK", sdk);
};

export const setInvocationIsValid = (invocationIsValid: boolean) => {
  return typedAction("app/SET_INVOKATION_IS_VALID", invocationIsValid);
};

export const setDeviceIsSupported = (deviceIsSupported: boolean) => {
  return typedAction("app/SET_DEVICE_IS_SUPPORTED", deviceIsSupported);
};

export const setBrowserIsSupported = (browserIsSupported: boolean) => {
  return typedAction("app/SET_BROWSER_IS_SUPPORTED", browserIsSupported);
};

export const setScreenIsBigEnough = (screenIsBigEnough: boolean) => {
  return typedAction("app/SET_SCREEN_IS_BIG_ENOUGH", screenIsBigEnough);
};

export const setNeedsTouchSensitivity = (needsTouchSensitivity: boolean) => {
  return typedAction("app/SET_NEEDS_TOUCH_SENSITIVITY", needsTouchSensitivity);
};

export const setTouchSensitivityIsSet = (touchSensitivityIsSet: boolean) => {
  return typedAction("app/SET_TOUCH_SENSITIVITY_IS_SET", touchSensitivityIsSet);
};

type AppAction = ReturnType<
  | typeof setErrorCode
  | typeof saveSDK
  | typeof setInvocationIsValid
  | typeof setDeviceIsSupported
  | typeof setBrowserIsSupported
  | typeof setScreenIsBigEnough
  | typeof setNeedsTouchSensitivity
  | typeof setTouchSensitivityIsSet
>;

export function appReducer(state = initialState, action: AppAction): AppState {
  switch (action.type) {
    case "app/SET_ERRORCODE":
      return {
        ...state,
        errorCode: action.payload,
      };
    case "app/SAVE_SDK":
      return {
        ...state,
        sdk: action.payload,
      };
    case "app/SET_INVOKATION_IS_VALID":
      return {
        ...state,
        invocationIsValid: action.payload,
      };
    case "app/SET_DEVICE_IS_SUPPORTED":
      return {
        ...state,
        deviceIsSupported: action.payload,
      };
    case "app/SET_BROWSER_IS_SUPPORTED":
      return {
        ...state,
        browserIsSupported: action.payload,
      };
    case "app/SET_SCREEN_IS_BIG_ENOUGH":
      return {
        ...state,
        screenIsBigEnough: action.payload,
      };
    case "app/SET_NEEDS_TOUCH_SENSITIVITY":
      return {
        ...state,
        needsTouchSensitivity: action.payload,
      };
    case "app/SET_TOUCH_SENSITIVITY_IS_SET":
      return {
        ...state,
        touchSensitivityIsSet: action.payload,
      };
    default:
      return state;
  }
}
