import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { Transition } from "@headlessui/react";
import classNames from "classnames";

interface Props {
  relativePositioning?: boolean;
}

const HookMessage: React.FC<Props> = (props) => {
  const { results } = useSelector((state: RootState) => {
    return {
      results: state.swipe.swipeResults,
    };
  });

  return (
    <div className="relative">
      {results.map((result, index) => (
        <Transition
          show={result.shouldRender === true}
          enter="transition ease-in-out duration-1000 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-1000 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          key={index}
        >
          <div
            className={classNames("w-full bg-white", {
              "absolute top-0": !props.relativePositioning,
            })}
          >
            <div className="mx-4 mt-4 mb-2 text-lg font-bold text-center">
              {result.title}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: result.message,
              }}
              className="mx-4 my-2 text-center whitespace-pre-wrap"
            ></div>
          </div>
        </Transition>
      ))}
    </div>
  );
};

HookMessage.defaultProps = {
  relativePositioning: false,
};

export default HookMessage;
