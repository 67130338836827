import { combineReducers } from "redux";
import { appReducer } from "./modules/app";
import { swipeReducer } from "./modules/swipe";

export const rootReducer = combineReducers({
  app: appReducer,
  swipe: swipeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
