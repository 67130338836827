import React from "react";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { clearSwipeResults } from "../redux/modules/swipe";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import LockedHeightNoHeader from "../components/common/layouts/LockedHeightNoHeader";
import { setErrorCode } from "../redux/modules/app";
import { setIsSuccess } from "../redux/modules/swipe";
import { ErrorCode, SwipeType } from "../enums";
import BackButton from "../components/common/ui/BackButton";
import VerifiedIcon from "../components/result/VerifiedIcon";
import Button from "../components/common/ui/Button";

import schein_strip from "../assets/img/result/verified_paper@2x.png";
import streifen_strip from "../assets/img/result/verified_strip@2x.png";
import schein_thread from "../assets/img/result/verified_banknote@2x.png";
import streifen_thread from "../assets/img/result/verified_thread@2x.png";
import anime from "animejs";

// types
const mapStateToProps = (state: RootState) => ({
  count: state.swipe.count,
  isSuccess: state.swipe.isSuccess,
  invocationIsValid: state.app.invocationIsValid,
  swipeType: state.swipe.swipeType,
});

const mapDispatchToProps = {
  clearSwipeResults,
  setErrorCode,
  setIsSuccess,
};

type ResultProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation &
  RouteComponentProps;

type ResultStates = {
  redirect: any;
};

// component
class Result extends React.Component<ResultProps, ResultStates> {
  result: string;

  constructor(props: ResultProps) {
    super(props);

    this.state = {
      redirect: null,
    };

    this.result = "";

    if (this.props.swipeType === SwipeType.Strip && this.props.isSuccess) {
      this.result = "strip_valid";
    } else if (
      this.props.swipeType === SwipeType.Strip &&
      !this.props.isSuccess
    ) {
      this.result = "strip_invalid";
    } else if (
      this.props.swipeType === SwipeType.Thread &&
      this.props.isSuccess
    ) {
      this.result = "thread_valid";
    } else if (
      this.props.swipeType === SwipeType.Thread &&
      !this.props.isSuccess
    ) {
      this.result = "thread_invalid";
    }
  }

  componentDidMount() {
    if (!this.props.invocationIsValid) {
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }

    setTimeout(() => {
      this.startAnimation();
    }, 500);
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  goBack = () => {
    this.props.setIsSuccess(false);
    this.setState({
      redirect: "/app",
    });
  };

  startAnimation = () => {
    // TODO
    anime({
      targets: ["#header", "#center", "#bottom"],
      opacity: 1.0,
      duration: 500,
      easing: "linear",
    });
    anime({
      targets: "#streifen",
      scale: 2.5,
      duration: 1000,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
    anime({
      targets: "#verified_strip",
      translateY: 80,
      duration: 1000,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
    anime({
      targets: "#verified_thread",
      translateX: -20,
      duration: 1000,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    let header = <></>;
    let center = <></>;

    switch (this.result) {
      case "strip_valid":
        header = (
          <div className="mt-4 text-center">
            <p className="text-lg font-bold">Successfully verified</p>
          </div>
        );
        center = (
          <div className="text-center">
            <div className="relative flex justify-center h-40">
              <img
                src={schein_strip}
                alt=""
                id="schein"
                className="absolute h-40 mx-auto"
              />
              <img
                src={streifen_strip}
                alt=""
                id="streifen"
                className="absolute h-40 mx-auto"
              />
            </div>
            <div id="verified_strip" className="mt-5">
              <VerifiedIcon type="valid" />
              <p className="mt-5 text-2xl font-bold text-green-600">
                Authentic Smart SAFE<sup>2</sup> II
              </p>
            </div>
          </div>
        );
        break;
      case "thread_valid":
        header = (
          <div className="mt-4 text-center">
            <p className="text-lg font-bold">Successfully verified</p>
          </div>
        );
        center = (
          <div className="text-center">
            <div className="relative flex justify-center h-40">
              <img
                src={schein_thread}
                alt=""
                id="schein"
                className="absolute h-40 mx-auto"
              />
              <img
                src={streifen_thread}
                alt=""
                id="streifen"
                className="absolute h-40 mx-auto"
                style={{ transformOrigin: "87% 50%" }}
              />
            </div>
            <div id="verified_thread" className="mt-5">
              <VerifiedIcon type="valid" />
              <p className="mt-5 text-2xl font-bold text-green-600">
                Authentic Printed Bimetal
              </p>
            </div>
          </div>
        );
        break;
      case "strip_invalid":
        header = (
          <div className="mt-4 text-center">
            <p className="text-lg font-bold">Feature could not be verified</p>
            <p className="mx-10 mt-4">
              We could not detect the Smart SAFE<sup>2</sup> II.
              <br />
              Please check the other security features for further
              authentication.
            </p>
          </div>
        );
        center = <VerifiedIcon type="invalid" />;
        break;
      case "thread_invalid":
        header = (
          <div className="mt-4 text-center">
            <p className="text-lg font-bold">Feature could not be verified</p>
            <p className="mx-10 mt-4">
              We could not detect the Printed Bimetal.
              <br />
              Please check the other security features for further
              authentication.
            </p>
          </div>
        );
        center = <VerifiedIcon type="invalid" />;
        break;

      default:
        break;
    }

    return (
      <div>
        <Helmet>
          <meta
            name="viewport"
            content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />

          <meta name="format-detection" content="telephone=no" />
          <meta name="msapplication-tap-highlight" content="no" />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="HUECK" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />

          <title>Result</title>
        </Helmet>
        <LockedHeightNoHeader>
          <div className="flex flex-col justify-between h-screen">
            <div id="header" style={{ opacity: 0 }}>
              {header}
            </div>
            <div id="center" style={{ opacity: 0 }}>
              {center}
            </div>
            <div
              className="w-full px-10 mb-4"
              id="bottom"
              style={{ opacity: 0 }}
            >
              <Button onClick={() => {}} primary={false} className="w-full">
                <p className="w-full font-medium leading-normal">
                  Click here to check the other
                  <br />
                  security features of your banknote.
                </p>
              </Button>
            </div>
          </div>
          <div className="absolute top-0">
            <BackButton onClick={this.goBack} />
          </div>
        </LockedHeightNoHeader>
      </div>
    );
  }
}

export default withRouter(
  compose<any>(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
  )(Result)
);
