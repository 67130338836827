import React, { ReactNode } from "react";
import classNames from "classnames";

// types
type ButtonProps = {
  onClick: any;
  className?: string;
  disabled?: boolean;
  primary?: boolean;
  children: ReactNode;
};

// component
class Button extends React.Component<ButtonProps> {
  static defaultProps = {
    primary: true,
  };

  render() {
    return (
      <button
        type="button"
        className={classNames(
          "inline-flex items-center px-3 py-5 text-sm leading-4 rounded-md",
          { "cursor-not-allowed": this.props.disabled },
          { "": !this.props.disabled },
          // primary, enabled
          {
            "text-white bg-edding-blue":
              this.props.primary && !this.props.disabled,
          },
          // secondary, enabled
          {
            "text-gray-500 bg-gray-300":
              !this.props.primary && !this.props.disabled,
          },
          this.props.className
        )}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </button>
    );
  }
}

export default Button;
