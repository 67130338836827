import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

import halfCircle from "../../../assets/img/swipe/progress-circle_half@2x.png";
import quarterCircle from "../../../assets/img/swipe/progress-circle_quarter@2x.png";

type Props = {};

function SwipeCounter(props: Props) {
  const { swipeCounter } = useSelector((state: RootState) => {
    return {
      swipeCounter: state.swipe.swipeCounter,
    };
  });

  return (
    <div className="relative">
      <div className="absolute top-0 w-12 h-12">
        <img
          src={quarterCircle}
          id="quarterCircle"
          alt=""
          className="absolute bottom-0 w-12 h-auto animate-antispin-ease"
        />
        <img
          src={halfCircle}
          id="halfCircle"
          alt=""
          className="absolute bottom-0 w-12 h-auto animate-spin-ease"
        />
      </div>
      <div className="flex items-center justify-center w-12 h-12 text-edding-blue">
        {swipeCounter * 20}%
      </div>
    </div>
  );
}

export default SwipeCounter;
