import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "../../../redux";
import { connect } from "react-redux";
import anime from "animejs";
import {
  setNetworkStatus,
  setProgress,
  setShowDot,
  setShowRedAlert,
  setShowGreenAlert,
  setCount,
  setSwipeCounter,
  setIsSuccess,
  setShowButtons,
  addSwipeResult,
  setSwipeResults,
  SwipeResult,
} from "../../../redux/modules/swipe";
import { SwipeType } from "../../../enums";
import ScaledImage from "../../common/ScaledImage";
import { withTranslation, WithTranslation } from "react-i18next";

import stripFrame from "../../../assets/img/swipe/scan_strip@2x.png";
import threadFrame from "../../../assets/img/swipe/scan_thread@2x.png";

import scanEdge from "../../../assets/img/swipe/scan_edge@2x.png";
import dot_start from "../../../assets/img/swipe/scan_start-dot@2x.png";
import dot_end from "../../../assets/img/swipe/dot@2x.png";

import pfeilTop from "../../../assets/img/swipe/pfeil@2x.png";
import pfeilBottom from "../../../assets/img/swipe/pfeil@2x.png";
import pfeilLinie from "../../../assets/img/swipe/pfeil-linie_lang@2x.png";
import swiper from "../../../assets/img/swipe/swipe-escalator@2x.png";
import pfeilMarker from "../../../assets/img/swipe/swipe-escalator-erweiterung@2x.png";

import thumb from "../../../assets/img/swipe/scan_thumb@2x.png";
import hand from "../../../assets/img/swipe/scan_hand@2x.png";

import touchPrint from "../../../assets/img/swipe/touch_fingerabdruck@2x.png";

import successAlert from "../../../assets/sounds/success.mp3";
import errorAlert from "../../../assets/sounds/error.mp3";

import {
  ConnectivityResponse,
  ConnectivityStatus,
  PrismaSDK,
} from "@prismadelabs/prismaid";
import { Transition } from "@headlessui/react";
import { compose } from "redux";
import ScaledImageUnpositioned from "../../common/ScaledImageUnpositioned";
import SwipeCounter from "./SwipeCounter";

// types
const mapStateToProps = (state: RootState) => ({
  swipeType: state.swipe.swipeType,

  count: state.swipe.count,
  swipeCounter: state.swipe.swipeCounter,
  isSuccess: state.swipe.isSuccess,
  showDot: state.swipe.showDot,
  showRedAlert: state.swipe.showRedAlert,
  showGreenAlert: state.swipe.showGreenAlert,
  swipeResults: state.swipe.swipeResults,
  sdk: state.app.sdk,
  scaleFactor: state.swipe.scaleFactor,
});

const mapDispatchToProps = {
  setNetworkStatus,
  setProgress,
  setShowDot,
  setShowRedAlert,
  setShowGreenAlert,
  setCount,
  setSwipeCounter,
  setIsSuccess,
  setShowButtons,
  addSwipeResult,
  setSwipeResults,
};

type SwipeFieldProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation &
  RouteComponentProps;

type SwipeFieldStates = {
  redirect: any;
};

// component
class SwipeField extends React.Component<SwipeFieldProps, SwipeFieldStates> {
  private sdk: PrismaSDK;
  private successSound: HTMLAudioElement;
  private errorSound: HTMLAudioElement;

  private swipeErrorCounter: number;
  private swipeSuccessCounter: number;

  private isRedirecting: boolean;

  constructor(props: SwipeFieldProps) {
    super(props);

    this.state = {
      redirect: null,
    };

    this.successSound = new Audio(successAlert);
    this.errorSound = new Audio(errorAlert);

    this.swipeErrorCounter = 0;
    this.swipeSuccessCounter = 0;
    this.props.setSwipeCounter(0);
    this.props.setNetworkStatus(ConnectivityStatus.ok);

    this.isRedirecting = false;

    this.sdk = this.prepareSDK();

    if (this.props.swipeType === SwipeType.Strip) {
      this.props.addSwipeResult({
        title: "Apply",
        message:
          "Place Smart SAFE<sup>2</sup> II on the screen\nand hold the banknote with your left thumb.",
        shouldRender: true,
      });
    } else {
      this.props.addSwipeResult({
        title: "Apply",
        message:
          "Place Printed Bimetal on the screen\nand hold the banknote with your left thumb.",
        shouldRender: true,
      });
    }
  }

  componentDidMount() {
    this.initialisePrismaSDK();
  }

  prepareSDK() {
    if (this.props.swipeType === SwipeType.Strip) {
      console.log("configure sdk for strip");
      this.sdk = new PrismaSDK(process.env.REACT_APP_SDK_API_KEY_STRIP!);
      // listen for touchcancel as WRONG CODE
      document.addEventListener("touchcancel", () => {
        console.log("touchcancel event-> wrong code for strip");
        this.sdk.pause();
        setTimeout(() => {
          this.redirectToFailurePage();
        }, 500);
      });
    } else {
      console.log("configure sdk for thread");
      this.sdk = new PrismaSDK(process.env.REACT_APP_SDK_API_KEY_THREAD!);
    }
    this.sdk.setTwoFingerHoldingMode(true);

    this.sdk.getProgressSubject().subscribe((response) => {
      console.log("*) progress:", response.progress);
      this.props.setProgress(response.progress);
    });

    this.sdk
      .getConnectivitySubject()
      .subscribe((response: ConnectivityResponse) => {
        console.log("*) connectivity response:", response.status);

        if (response.status === null) return;

        this.props.setNetworkStatus(response.status);
      });

    return this.sdk;
  }

  initialisePrismaSDK = () => {
    this.sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) detection success:", response.description());

      if (response.codeId === "code1") {
        // probably good code
        this.increaseSwipeSuccessCounter();
      } else {
        // bad code or swipeError
        this.flashRedAlert();
        this.increaseSwipeErrorCounter();
      }
      this.increaseSwipeCounter();
    });

    this.sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());

      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });

      if (response.hints.length > 0) {
        this.props.setCount(this.props.count + 1);

        this.updateMessages({
          title: this.props.t("swipe:swipe.title"),
          message: this.props.t("swipe:" + response.hints[0].code),
        });
      }

      this.flashRedAlert();

      this.increaseSwipeErrorCounter();
      this.increaseSwipeCounter();
    });

    this.sdk.getInteractionSubject().subscribe((response) => {
      console.log(
        "*) interaction event:",
        response.event,
        response.activeSignals
      );

      switch (response.event) {
        case "started":
          this.props.setShowDot(true);
          if (this.props.count === 0) {
            if (this.props.swipeType === SwipeType.Strip) {
              this.updateMessages({
                title: this.props.t("swipe:swipe.title"),
                message: this.props.t("swipe:swipe.body.strip"),
              });
            } else {
              this.updateMessages({
                title: this.props.t("swipe:swipe.title"),
                message: this.props.t("swipe:swipe.body.thread"),
              });
            }
          }
          break;
        case "complete":
          this.props.setShowDot(false);
          break;

        default:
          break;
      }
    });

    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      this.sdk.attachToElement(screen);
    }

    setTimeout(() => {
      this.startDemoAnimation();
    }, 1000);
  };

  updateMessages = (result: SwipeResult) => {
    let tmp = this.props.swipeResults;
    let count = tmp.push(result);

    tmp.forEach((value, index) => {
      if (index === count - 1) {
        value.shouldRender = true;
      } else {
        value.shouldRender = false;
      }
    });

    this.props.setSwipeResults(tmp);
  };

  flashRedAlert = () => {
    this.props.setShowRedAlert(true);

    setTimeout(() => {
      this.props.setShowRedAlert(false);
    }, 155);
  };

  flashGreenAlert = () => {
    this.props.setShowGreenAlert(true);

    setTimeout(() => {
      this.props.setShowGreenAlert(false);
    }, 155);
  };

  increaseSwipeErrorCounter = () => {
    this.swipeErrorCounter++;

    if (this.swipeErrorCounter >= 5) {
      this.redirectToFailurePage();
    }
  };

  increaseSwipeSuccessCounter = () => {
    this.swipeSuccessCounter++;

    if (this.props.swipeType === SwipeType.Strip) {
      this.redirectToSuccessPage();
    } else {
      // is SwipeType.Thread
      if (this.swipeSuccessCounter >= 4) {
        this.redirectToSuccessPage();
      }
    }
  };

  increaseSwipeCounter = () => {
    this.props.setSwipeCounter(this.props.swipeCounter + 1);

    if (this.props.swipeCounter < 5) {
      return;
    }

    if (this.props.swipeType === SwipeType.Strip) {
      return;
    }

    if (this.swipeSuccessCounter === 5) {
      this.redirectToSuccessPage();
    } else {
      this.redirectToFailurePage();
    }
  };

  redirectToSuccessPage = () => {
    if (this.isRedirecting === true) {
      return;
    }
    this.isRedirecting = true;

    this.props.setIsSuccess(true);
    this.flashGreenAlert();
    this.successSound.play();
    this.props.history.push("/result");
  };

  redirectToFailurePage = () => {
    if (this.isRedirecting === true) {
      return;
    }
    this.isRedirecting = true;

    this.props.setIsSuccess(false);
    this.errorSound.play();
    this.props.history.push("/result");
  };

  startDemoAnimation = () => {
    anime({
      targets: "#edge",
      opacity: 1.0,
      duration: 500,
      easing: "linear",
    });
    anime({
      targets: "#edge",
      scale: 1.2,
      duration: 1000,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
    setTimeout(() => {
      anime({
        targets: "#edge",
        opacity: 0.5,
        duration: 1000,
        easing: "linear",
        complete: function () {
          anime({
            targets: "#edge",
            opacity: 1.0,
            duration: 1000,
            loop: true,
            direction: "alternate",
            easing: "linear",
          });
        },
      });
    }, 2000);

    setTimeout(() => {
      anime({
        targets: "#touchPrint",
        opacity: 0.6,
        duration: 1000,
        easing: "linear",
        complete: function () {
          anime({
            targets: "#touchPrint",
            opacity: 0.4,
            duration: 1000,
            loop: true,
            direction: "alternate",
            easing: "linear",
          });
        },
      });
    }, 1000);

    setTimeout(() => {
      anime.set("#thumb", {
        opacity: 1,
        translateX: -100,
        translateY: 100,
        scale: 1.2,
      });
      anime({
        targets: "#thumb",
        scale: 1,
        translateX: 0,
        translateY: 0,
        easing: "linear",
      });
    }, 4000);

    setTimeout(() => {
      anime({
        targets: ["#pfeilBottom", "#pfeilTop", "#pfeilLinie"],
        opacity: 1.0,
        duration: 1000,
        easing: "linear",
      });
    }, 3000);

    setTimeout(() => {
      anime({
        targets: "#pfeilLinie",
        translateY: -(780 * this.props.scaleFactor),
        duration: 5000,
        loop: true,
        easing: "linear",
      });
    }, 4000);

    setTimeout(() => {
      anime.set("#hand", {
        opacity: 1,
        scale: 1.2,
        // translateX: 200,
        translateX: (1 / 2) * window.innerWidth,
        translateY: 200,
      });
      anime({
        targets: "#hand",
        keyframes: [
          // move hand in
          {
            scale: 1,
            translateX: 0,
            translateY: 0,
            duration: 1500,
            endDelay: 500,
          },
          //   swipe up
          {
            scale: 1,
            translateX: 0,
            translateY: -965 * this.props.scaleFactor,
            duration: 2000,
            endDelay: 500,
            easing: "easeInSine",
          },
          // move hand out
          {
            scale: 1.2,
            translateX: (1 / 2) * window.innerWidth + 50,
            translateY: -700 * this.props.scaleFactor,
            duration: 1000,
            endDelay: 1000,
          },
        ],
        easing: "linear",
        loop: true,
      });
    }, 5000);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    let swipeField;
    let swipeCounter;
    let touch_y;
    let marker_top_y;
    let marker_bottom_y;
    let arrow_top_y;
    let arrow_bottom_y;
    let escalator_y;

    if (this.props.swipeType === SwipeType.Strip) {
      swipeField = (
        <>
          <ScaledImage
            src={stripFrame}
            id="frame"
            alt="stripFrame"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="ml-2"
            horizontalOffset={-495}
            verticalOffset={-120}
          />
        </>
      );
      touch_y = -470;
      marker_top_y = -1082;
      marker_bottom_y = -98;
      arrow_top_y = -1000;
      arrow_bottom_y = -130;
      escalator_y = 200;
    } else {
      swipeField = (
        <>
          <ScaledImage
            src={threadFrame}
            id="frame"
            alt="threadFrame"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="ml-2"
            horizontalOffset={-1110}
            verticalOffset={-120}
          />
          <ScaledImage
            src={scanEdge}
            id="edge"
            alt="scanEdge"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
            horizontalOffset={535}
            verticalOffset={-885}
            opacity={0}
          />
        </>
      );

      swipeCounter = (
        <div className="absolute top-0 ml-10 mt-28">
          <SwipeCounter />
        </div>
      );

      touch_y = -450;
      marker_top_y = -1030;
      marker_bottom_y = -98;
      arrow_top_y = -940;
      arrow_bottom_y = -130;
      escalator_y = 170;
    }

    return (
      <div className="absolute top-0 left-0 w-screen h-screen">
        {swipeField}
        {swipeCounter}
        <ScaledImage
          src={touchPrint}
          id="touchPrint"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={-80}
          verticalOffset={touch_y}
          opacity={0}
        />
        <Transition
          show={this.props.showDot}
          enter="transition-opacity duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ScaledImage
            src={pfeilMarker}
            id="markerTop"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={426}
            verticalOffset={marker_top_y}
          />

          <ScaledImage
            src={pfeilMarker}
            id="markerBottom"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={426}
            verticalOffset={marker_bottom_y}
          />

          <ScaledImage
            src={dot_end}
            id="dot_end"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={418}
            verticalOffset={-1025}
          />
          <ScaledImage
            src={dot_start}
            id="dot_start"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={465}
            verticalOffset={-110}
          />
        </Transition>
        <ScaledImage
          src={pfeilTop}
          id="pfeilTop"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={426}
          verticalOffset={arrow_top_y}
          opacity={0}
        />
        <div
          className="absolute overflow-hidden"
          style={{
            width: this.props.scaleFactor * 99 + "px",
            height: this.props.scaleFactor * 770 + "px",
            left: this.props.scaleFactor * 426 + "px",
            bottom: this.props.scaleFactor * escalator_y + "px",
          }}
        >
          <ScaledImageUnpositioned
            src={pfeilLinie}
            id="pfeilLinie"
            alt=""
            className="top-0 "
            opacity={0}
          />
        </div>
        <ScaledImage
          src={pfeilBottom}
          id="pfeilBottom"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={426}
          verticalOffset={arrow_bottom_y}
          opacity={0}
        />
        <ScaledImage
          src={swiper}
          id="swiper"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={395}
          verticalOffset={790}
        />
        <ScaledImage
          src={thumb}
          id="thumb"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={-350}
          verticalOffset={-290}
          opacity={0}
        />
        <ScaledImage
          src={hand}
          id="hand"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={440}
          verticalOffset={660}
          opacity={0}
        />

        <Transition
          show={this.props.showRedAlert}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div id="redAlert" className="h-screen bg-opacity-75 bg-status-red" />
        </Transition>
        <Transition
          show={this.props.showGreenAlert}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            id="greenAlert"
            className="h-screen bg-opacity-75 bg-status-green"
          />
        </Transition>
      </div>
    );
  }
}

export default withRouter(
  compose<any>(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
  )(SwipeField)
);
