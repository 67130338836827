import React, { createRef } from "react";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import ActiveSwipeCard from "../components/swipe/card/ActiveSwipeCard";
import ProgressBar from "../components/swipe/ProgressBar";
import NetworkIndicator from "../components/swipe/NetworkIndicator";
import SwipeField from "../components/swipe/card/SwipeField";
import { Helmet } from "react-helmet";
import { clearSwipeResults } from "../redux/modules/swipe";
import LockedHeightNoHeader from "../components/common/layouts/LockedHeightNoHeader";
import CloseButton from "../components/common/ui/CloseButton";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import OrientationLock from "../components/common/layouts/OrientationLock";
import { setErrorCode } from "../redux/modules/app";
import { ErrorCode } from "../enums";

// types
const mapStateToProps = (state: RootState) => ({
  invocationIsValid: state.app.invocationIsValid,
  swipeType: state.swipe.swipeType,
});

const mapDispatchToProps = {
  clearSwipeResults,
  setErrorCode,
};

type SwipeProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps;

type SwipeStates = {
  redirect: any;
};

// component
class Swipe extends React.Component<SwipeProps, SwipeStates> {
  private containerRef = createRef<HTMLDivElement>();

  constructor(props: SwipeProps) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  componentDidMount() {
    const container = this.containerRef.current;
    if (container) {
      disableBodyScroll(container);
    }

    if (!this.props.invocationIsValid) {
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }
  }

  componentDidUpdate() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <OrientationLock>
        <div>
          <Helmet>
            <meta
              name="viewport"
              content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />

            <meta name="format-detection" content="telephone=no" />
            <meta name="msapplication-tap-highlight" content="no" />

            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-title" content="HUECK" />
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="black"
            />
            <title>Swipe</title>
          </Helmet>
          <div ref={this.containerRef} id="swipeScreen">
            <LockedHeightNoHeader>
              <ActiveSwipeCard style={{ touchAction: "none" }} />
              <SwipeField />
              <ProgressBar />
              <NetworkIndicator />
            </LockedHeightNoHeader>
          </div>
          <div className="absolute top-0 left-0">
            <CloseButton
              onClick={() => {
                this.props.clearSwipeResults();
                this.props.history.push("/app");
              }}
            />
          </div>
        </div>
      </OrientationLock>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Swipe));
