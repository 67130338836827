import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { setSwipeType } from "../redux/modules/swipe";
import { compose } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";
import Button from "../components/common/ui/Button";
import SimpleToggle from "../components/touch/TouchToggle";
import CenteredLayout from "../components/common/ui/CenteredLayout";
import Card from "../components/common/ui/Card";
import { setErrorCode } from "../redux/modules/app";
import { ErrorCode } from "../enums";
import { Helmet } from "react-helmet";

// types
const mapStateToProps = (state: RootState) => ({
  swipeType: state.swipe.swipeType,
  touchSensitivityIsSet: state.app.touchSensitivityIsSet,
  invocationIsValid: state.app.invocationIsValid,
});

const mapDispatchToProps = {
  setSwipeType,
  setErrorCode,
};

type TouchProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation &
  RouteComponentProps;

type TouchStates = {
  redirect: any;
};

// component
class Touch extends React.Component<TouchProps, TouchStates> {
  constructor(props: TouchProps) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  componentDidMount() {
    if (!this.props.invocationIsValid) {
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <CenteredLayout className="bg-gray-900 bg-opacity-75">
        <Helmet>
          <title>TouchSensitivity</title>
        </Helmet>
        <Card>
          <h1 className="text-lg font-bold">{this.props.t("touch:title")}</h1>
          <p className="mt-4">{this.props.t("touch:body")}</p>
          <div className="flex mt-4">
            {this.props.t("touch:label")}
            <SimpleToggle />
          </div>
          <div className="w-full mt-4">
            <Button
              onClick={() => {
                this.props.history.push("/swipe");
              }}
              disabled={!this.props.touchSensitivityIsSet}
              className="w-full"
            >
              <p className="w-full text-lg font-medium">
                {this.props.t("touch:button")}
              </p>
            </Button>
          </div>
        </Card>
      </CenteredLayout>
    );
  }
}

export default withRouter(
  compose<any>(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
  )(Touch)
);
