import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common_de from "./translations/de/common.json";
import invalid_de from "./translations/de/invalid.json";
import swipe_de from "./translations/de/swipe.json";
import touch_de from "./translations/de/touch.json";

import common_en from "./translations/en/common.json";
import invalid_en from "./translations/en/invalid.json";
import swipe_en from "./translations/en/swipe.json";
import touch_en from "./translations/en/touch.json";

const resources = {
  en: {
    common: common_en,
    invalid: invalid_en,
    swipe: swipe_en,
    touch: touch_en,
  },
  de: {
    common: common_de,
    invalid: invalid_de,
    swipe: swipe_de,
    touch: touch_de,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    // TODO: enable german/english
    // lng: "de",
    lng: "en",
    // fallbackLng: "en",
    // supportedLngs: ["en", "de"],

    fallbackNS: "common",

    debug: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "sup"],
    },
  });

export default i18n;
