import classNames from "classnames";
import React from "react";
import arrow from "../../../assets/img/arrow_left.svg";

// types
type CloseButtonProps = {
  onClick: any;
  className?: string;
};

// component
class BackButton extends React.Component<CloseButtonProps> {
  render() {
    return (
      <button
        className={classNames("p-4", this.props.className)}
        onClick={this.props.onClick}
        style={{ touchAction: "auto" }}
      >
        <img src={arrow} alt="left arrow" className="h-4" />
      </button>
    );
  }
}

export default BackButton;
