import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// pages
import Open from "./pages/Open";
import App from "./pages/App";
import Touch from "./pages/Touch";
import Swipe from "./pages/Swipe";
import Result from "./pages/Result";
import Invalid from "./pages/Invalid";

type RouterComponentSpecificProps = {
  history: any;
  location: any;
  match: any;
};

class RouterComponent extends React.Component<RouterComponentSpecificProps> {
  currentPathname: null;
  currentSearch: null;

  constructor(props: RouterComponentSpecificProps) {
    super(props);

    // Store the previous pathname and search strings
    this.currentPathname = null;
    this.currentSearch = null;
  }

  componentDidMount() {
    const { history } = this.props;

    history.listen(
      (newLocation: { pathname: null; search: null }, action: string) => {
        if (action === "PUSH") {
          if (
            newLocation.pathname !== this.currentPathname ||
            newLocation.search !== this.currentSearch
          ) {
            // Save new location
            this.currentPathname = newLocation.pathname;
            this.currentSearch = newLocation.search;

            // Clone location object and push it to history
            history.push({
              pathname: newLocation.pathname,
              search: newLocation.search,
            });
          }
        } else {
          // Send user back if they try to navigate back
          history.go(1);
        }
      }
    );
  }

  getTransitions = (path: string) => {
    console.log("transitionPath: ", path);
    let transitionClass = "";

    switch (path) {
      case "/app":
        transitionClass = "fadeIn";
        break;
      case "/touch":
        transitionClass = "fadeIn";
        break;
      default:
        transitionClass = "slideFromRight";
        break;
    }
    return transitionClass;
  };

  render() {
    return (
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              classNames={this.getTransitions(location.pathname)}
              timeout={600}
            >
              <Switch location={location}>
                <Route exact path="/" component={Open} />
                <Route path="/app" component={App} />
                <Route path="/touch" component={Touch} />
                <Route path="/swipe" component={Swipe} />
                <Route path="/result" component={Result} />

                <Route path="/error" component={Invalid} />
                <Route component={Invalid} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    );
  }
}

export default withRouter(RouterComponent);
