import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { setTouchSensitivityIsSet } from "../../redux/modules/app";

const mapStateToProps = function (state: RootState) {
  return {
    touchSensitivityIsSet: state.app.touchSensitivityIsSet,
  };
};

const mapDispatchToProps = {
  setTouchSensitivityIsSet,
};

type ToggleProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

function TouchToggle(props: ToggleProps) {
  return (
    <button
      type="button"
      aria-pressed={props.touchSensitivityIsSet}
      onClick={() => {
        props.setTouchSensitivityIsSet(!props.touchSensitivityIsSet);
      }}
      className={classNames(
        "relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-edding-blue rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
        { "bg-edding-blue": props.touchSensitivityIsSet },
        { "bg-white": !props.touchSensitivityIsSet }
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          "inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 rounded-full ring-0 border border-white",
          { "translate-x-5 bg-white": props.touchSensitivityIsSet },
          { "translate-x-0 bg-edding-blue": !props.touchSensitivityIsSet }
        )}
      ></span>
    </button>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TouchToggle);
