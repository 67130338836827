import React, { ReactNode } from "react";
import classNames from "classnames";

// types
type CardProps = {
  children: ReactNode;
  className?: string;
  small?: boolean;
};

// component
class Card extends React.Component<CardProps> {
  static defaultProps = {
    small: false,
  };

  render() {
    return (
      <div
        className={classNames(
          "overflow-hidden text-center bg-white rounded-lg shadow-lg whitespace-pre-wrap",
          { "px-4 py-5 sm:p-6": !this.props.small },
          { "px-3 py-3 sm:p-6": this.props.small },
          this.props.className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Card;
