export enum SwipeType {
  Strip = 0,
  Thread = 1,
}

export enum ErrorCode {
  UnsupportedDevice,
  UnsupportedBrowser,
  ScreenTooSmall,

  InvalidInvocation,

  InvalidScreen,
  InvalidSwipeType,
}
