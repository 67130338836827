import React from "react";
import { Redirect } from "react-router-dom";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { setErrorCode } from "../redux/modules/app";
import { setSwipeType } from "../redux/modules/swipe";
import { ErrorCode, SwipeType } from "../enums";
import ScrollableHeightNoHeader from "../components/common/layouts/ScrollableHeightNoHeader";
import Button from "../components/common/ui/Button";

import bg from "../assets/img/app/start_bg@2x.png";
import logo_hueck from "../assets/img/logos/start_hueck-folien_logo@2x.png";
import logo_prismade from "../assets/img/logos/start_prismade-logo@2x.png";
import strip from "../assets/img/app/start_security-strip@2x.png";
import thread from "../assets/img/app/start_security-thread@2x.png";

// types
const mapStateToProps = (state: RootState) => ({
  invocationIsValid: state.app.invocationIsValid,
  deviceIsSupported: state.app.deviceIsSupported,
  browserIsSupported: state.app.browserIsSupported,
  screenIsBigEnough: state.app.screenIsBigEnough,
});

const mapDispatchToProps = {
  setErrorCode,
  setSwipeType,
};

type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
  redirect: any;
};

// component
class App extends React.Component<AppProps, AppStates> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      redirect: null,
    };

    document.addEventListener("touchstart", tapHandler);

    var tappedTwice = false;

    function tapHandler(event: { preventDefault: () => void }) {
      if (!tappedTwice) {
        tappedTwice = true;
        setTimeout(function () {
          tappedTwice = false;
        }, 300);
        return false;
      }
      event.preventDefault();
    }
  }

  componentDidMount() {
    if (!this.props.invocationIsValid) {
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }

    if (!this.props.browserIsSupported) {
      this.setErrorCodeAndRedirect(ErrorCode.UnsupportedBrowser);
      return;
    }

    if (!this.props.deviceIsSupported) {
      this.setErrorCodeAndRedirect(ErrorCode.UnsupportedDevice);
      return;
    }
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  setTypeAndRedirect = (swipeType: SwipeType) => {
    this.props.setSwipeType(swipeType);

    if (!this.props.screenIsBigEnough) {
      this.setErrorCodeAndRedirect(ErrorCode.ScreenTooSmall);
      return;
    }

    this.setState({
      redirect: "/swipe",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <ScrollableHeightNoHeader
        className="justify-between text-center bg-cover"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="mx-6 mt-10 text-lg font-semibold">
          <p>
            Welcome to the Technology Demo
            <br />
            for Banknote Authentication
          </p>
        </div>
        <div className="">
          <img
            src={logo_hueck}
            alt="Logo HUECK Folien"
            className="h-32 mx-auto"
          />
          <img
            src={logo_prismade}
            alt="Logo Prismade"
            className="h-12 mx-auto mt-12"
          />
        </div>
        <div className="flex flex-col mx-6 mb-8 space-y-4">
          <Button
            onClick={() => {
              this.setTypeAndRedirect(SwipeType.Strip);
            }}
          >
            <div className="flex items-center justify-between w-full -my-2 text-lg">
              <div>
                Smart SAFE<sup>2</sup> II
              </div>
              <img src={strip} alt="" className="h-8" />
            </div>
          </Button>
        </div>
      </ScrollableHeightNoHeader>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
