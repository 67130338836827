import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { rootReducer } from "./redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import * as serviceWorker from "./serviceWorker";
// css
import "./index.css";

import RouterComponent from "./RouterComponent";
import { OrientationChangeProvider } from "./components/common/layouts/OrientationChangeProvider";

const store = createStore(rootReducer);

// routes
const routing = (
  <>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <OrientationChangeProvider>
          <Router>
            <RouterComponent />
          </Router>
        </OrientationChangeProvider>
      </Provider>
    </I18nextProvider>
  </>
);

// render
ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
