import classNames from "classnames";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const ScrollableHeightNoHeader: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames(
        "w-screen min-h-screen flex flex-col bg-white",
        props.className
      )}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default ScrollableHeightNoHeader;
